
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <!--col-xl-12 col-lg-12 col-md-12-->

      <DataTable :value="customers" :lazy="true" :paginator="true" class="p-datatable-customers" :rows="per_page"
        :totalRecords="totalRows" @page="onPage($event)" @sort="onSort($event)" dataKey="id" :rowHover="true"
        :filters="filters" :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="filterPage"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
        <!--:rowsPerPageOptions="[10, 25, 50]"-->
        <template #header>
          <div class="table-header">
            List of Orders
            <!-- <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <Dropdown v-model="filters.duration" :options="duration"  @change="topFilter($event.value,'duration')" placeholder="Select a type" class="p-column-filter" :showClear="true">
                <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                </template>
            </Dropdown>
               
            </span> -->
          </div>
        </template>
        <template #empty> No record found. </template>
        <template #loading> Loading data. Please wait. </template>
        <!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
        <Column field="name" header="Name" :sortable="true" filterMatchMode="equals">
          <template #body="slotProps">
            <span class="p-column-title">Name</span>

            {{ slotProps.data.user.name }}
          </template>
          <template #filter>
            <InputText type="text" v-model="filters.name" @keydown.enter="topFilter(filters.name, 'name')"
              class="p-column-filter" placeholder="Search by name" />
          </template>
        </Column>
        <Column field="order_type" header="Type" :sortable="true" filterMatchMode="equals">
          <template #body="slotProps">
            <span class="p-column-title">Type</span>
            <span :class="'customer-badge name-' + slotProps.data.order_type">{{
                slotProps.data.order_type
            }}</span>
          </template>
          <template #filter>
            <!-- <InputText type="text" v-model="filters.status" class="p-column-filter"  @keydown.enter="onFilter($event)" placeholder="Search by status"/> -->
            <Dropdown v-model="filters.order_type" @change="topFilter($event.value, 'order_type')" :options="order_type"
              placeholder="Select a type" class="p-column-filter" :showClear="true">
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                    slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </template>>
        </Column>

        <Column header="Quantity" :sortable="true" sortField="quantity" filterField="quantity"
          filterMatchMode="contains">
          <template #body="slotProps">
            <span class="p-column-title">Quantity</span>

            {{ slotProps.data.max_quantity }}
          </template>
          <template #filter>
            <InputText type="text" v-model="filters.max_quantity"
              @keydown.enter="topFilter(filters.max_quantity, 'quantity')" class="p-column-filter"
              placeholder="Search by quantity" />
          </template>
        </Column>
        <Column header="At Price" :sortable="true" sortField="price" filterField="price" filterMatchMode="contains">
          <template #body="slotProps">
            <span class="p-column-title">At Price</span>

            {{ slotProps.data.at_price }}
          </template>
          <template #filter>
            <InputText type="text" v-model="filters.at_price" @keydown.enter="topFilter(filters.at_price, 'price')"
              class="p-column-filter" placeholder="Search by price" />
          </template>
        </Column>
        <Column header="Total" :sortable="true" sortField="total_price" filterField="total_price"
          filterMatchMode="contains">
          <template #body="slotProps">
            <span class="p-column-title">total_price</span>

            {{ slotProps.data.total }}
          </template>
          <template #filter>
            <InputText type="text" v-model="filters.total" @keydown.enter="topFilter(filters.total, 'total_price')"
              class="p-column-filter" placeholder="Search by total_price" />
          </template>
        </Column>

        <Column header="Date" :sortable="true" sortField="created_at" filterField="created_at"
          filterMatchMode="contains">
          <template #body="slotProps">
            <span class="p-column-title">Created At</span>
            <span :class="'customer-badge status-' + slotProps.data.created_at">{{
                new Date(slotProps.data.created_at).toLocaleDateString()
            }}</span>
          </template>
          <template #filter>
            <Calendar v-model="filters.date" :showButtonBar="true" dateFormat="yy-mm-dd" class="p-column-filter"
              @date-select="topFilter(filters.date, 'date')" placeholder="Search by date" />
          </template>
        </Column>

        <Column field="status" header="Status" :sortable="true" filterMatchMode="equals">
          <template #body="slotProps">
            <span class="p-column-title">Status</span>
            <span :class="'customer-badge status-' + slotProps.data.status">{{ slotProps.data.status }}</span>
          </template>
          <template #filter>
            <!-- <InputText type="text" v-model="filters.status" class="p-column-filter"  @keydown.enter="onFilter($event)" placeholder="Search by status"/> -->
            <Dropdown v-model="filters.status" @change="topFilter($event.value, 'status')" :options="cryptoStatus"
              optionValue="key" optionLabel="value" placeholder="Select a Status" class="p-column-filter"
              :showClear="true">
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                    slotProps.option.value
                }}</span>
              </template>
            </Dropdown>
          </template>>
        </Column>
      </DataTable>

    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import ApiClass from "../../../api/api";
import filterPage from "../../../jsonFiles/filterPage.json";
import PageHeader from "@/components/page-header";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import ColumnGroup from 'primevue/columngroup';
import InputText from "primevue/inputtext";
// import MultiSelect from 'primevue/multiselect';
import Dropdown from "primevue/dropdown";

import Calendar from "primevue/calendar";
// import ProgressBar from 'primevue/progressbar';

export default {
  components: {
    Layout,
    PageHeader,
    DataTable,
    Dropdown,
    Calendar,
    Column,
    InputText,


  },
  watch: {
    modelvalue(v) {
      this.BuyerSellerData[0].series = [parseFloat(v.buyer_total).toFixed(2)];
      this.BuyerSellerData[1].series = [parseFloat(v.seller_total).toFixed(2)];
    },
  },
  data() {
    return {
      filterPage: filterPage.allpages,
      totalRows: 1,
      per_page: 10,
      page: 1,
      title: "P2P",
      items: [
        {
          text: "Manage P2P",
          href: "#",
        },
        {
          text: "P2P",
          active: true,
        },
      ],
      filters: {
        order_type: null,
        status: null,
        quantity: null,
        price: null,
        total_price: null,
        coin: null,
        date: null,
      },
      filter: "",
      customers: [],
      selectedCustomers: null,
      loading: true,
      crypto: [],
      total_qty: 0,

      cryptoStatus: [{ key: "processing", value: "processing" }, { key: "placed", value: "placed" },
      { key: "completed", value: "completed" },
      { key: "cancelled", value: "cancelled" }, { key: "partially_completed", value: "partially_completed" }],
      duration: ["day", "week", "month"],
      order_type: ["buy", "sell"],
      currency: [],

      allCurrency: [],
      modelvalue: [],

      symbol: "BTC",

    };
  },
  created() { },
  async mounted() {
    this.client();

  },
  methods: {
    async client() {
      let response = await ApiClass.getNodeRequest("P2P/P2POrder/allorderlist?per_page=" + this.per_page + "&page=" + this.page + "&" + this.filter, true);
      // let response = await ApiClass.getNodeRequest("P2P/cryptotransfer/order-list?per_page=" + this.per_page + "&page=" + this.page + "&" + this.filter, true);
      console.log("response.data.data ==", response);
      if (response.data.status_code == 1) {
        this.loading = false;
        this.customers = response.data.data.data;
        // console.log("rahul", this.customers);
        this.currentPage = response.data.data.current_page;
        this.totalRows = response.data.data.total;
        this.total_qty = response.data.data.total_qty;
      }

      // "admin/p2p/get_orders?per_page=" +
      //   this.per_page +
      //   "&page=" +
      //   this.page +
      //   "&" +
      //   this.filter
      // ).then((res) => {
      //   if (res.data.status_code == 1) {
      //     this.loading = false;
      //     this.customers = res.data.data.data;
      //     console.log("rahul" ,  this.customers);
      //     this.currentPage = res.data.data.current_page;
      //     this.totalRows = res.data.data.total;
      //     this.total_qty = res.data.data.total_qty;
      //     // console.log("thiss", this.customers);
      //   }
      // });
    },



    onPage(event) {
      if (this.page != event.page + 1) {
        this.loading = !this.loading;
        this.page = event.page + 1;
      }
      if (this.per_page != event.rows) {
        this.loading = !this.loading;
        this.per_page = event.rows;
      }
      this.client();
    },
    onSort(e) {

      for (let elKey in this.filters) {
        //console.log(elKey);
        this.filters[elKey] = null;
      }

      this.loading = !this.loading;
      var sortby = e.sortOrder == 1 ? "asc" : "desc";
      this.filter = "sortby=" + sortby + "&sortbyname=" + e.sortField;

      this.client();
    },
    onFilter() { },

    topFilter(columnVal, columnName) {

      if (columnName == "date") {
        columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
        columnVal = columnVal.split("/").reverse().join("-");
        // columnVal = columnVal.split("/").join("-");
      }
      this.loading = !this.loading;

      this.$set(this.filters, columnName, columnVal);

      var x = this.filters;
      console.log("value of x", x)
      const resulted = JSON.parse(JSON.stringify(x), (key, value) =>
        value === null || value === "" ? undefined : value
      );

      let query = new URLSearchParams(resulted).toString();

      this.filter = query;

      this.client();
    },
  },
};
</script>
  <style lang="scss">
  .p-paginator {
    .p-paginator-current {
      margin-left: auto;
    }
  }
  
  .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;
  
    .p-progressbar-value {
      background-color: #00acad;
    }
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
  }
  
  .p-datepicker {
    min-width: 25rem;
  
    td {
      font-weight: 400;
    }
  }
  
  .p-datatable.p-datatable-customers {
    .p-datatable-header {
      padding: 1rem;
      text-align: left;
      font-size: 1.5rem;
    }
  
    .p-paginator {
      padding: 1rem;
    }
  
    .p-datatable-thead>tr>th {
      text-align: left;
    }
  
    .p-datatable-tbody>tr>td {
      cursor: auto;
    }
  
    .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
    }
  }
  
  .p-calendar .p-datepicker {
    width: 350px;
  }
  
  .p-inputtext {
    width: 100%;
  }
  
  /* Responsive */
  .p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
  }
  
  @media screen and (max-width: 64em) {
    .p-datatable {
      &.p-datatable-customers {
  
        .p-datatable-thead>tr>th,
        .p-datatable-tfoot>tr>td {
          display: none !important;
        }
  
        .p-datatable-tbody>tr>td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;
  
          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }
        }
      }
    }
  }
  </style>



